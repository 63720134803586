import React, { useState, useEffect } from "react";

import PanelTemplate from "../../templates/Panel";
import InputSelect from "../../components/InputSelect";
import Input from "../../components/Input";
import api from "../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import Box from "../../components/Box";
import PageFooter from "../../components/PageFooter";
import Button from "../../components/Button";
import { renderError } from "../../helpers/errors";
import InputColor from "../../components/InputColor";
import { Loading } from "../../components/Loading";
import DotStatus from "../../components/DotStatus";
import { ModalDelete } from "../../components/ModalDelete";
import { toast } from "react-toastify";


const planosOptions = [
    { label: 'Teste', value: 'teste'},
    { label: 'Básico', value: 'basic'},
    { label: 'Básico Plus', value: 'basic-plus'},
    { label: 'Standard', value: 'standard'},
    { label: 'Pro', value: 'pro'}
];

const statusOptions = [
    { label: 'Ativo', value: 'ativo'},
    { label: 'Inativo', value: 'inativo'}
];

const projectsOptions = [
    { label: 'Padrão', value: 'prj_XunUpgDuAPntOq7YFqtkRlznpnfw'},
    { label: 'Kopu', value: 'prj_fsUVwKTfBlXaQCo56i4vqZgVK81K'}
];

export const TenantCreate = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [tenant, setTenant] = useState(null);
    const [nome, setNome] = useState('');
    const [status, setStatus] = useState('');
    const [plano, setPlano] = useState('');
    const [corPrimaria, setCorPrimaria] = useState('#cccccc');
    const [corSecundaria, setCorSecundaria] = useState('#cccccc');
    const [corTerciaria, setCorTerciaria] = useState('#cccccc');
    const [domain, setDomain] = useState('');
    const [subdomain, setSubdomain] = useState('');
    const [vercelProjectId, setVercelProjectId] = useState('');

    const [logoPngUrl, setLogoPngUrl] = useState(null);
    const [faviconUrl, setFaviconUrl] = useState(null);

    const [integracaoXbzUrl, setIntegracaoXbzUrl] = useState('');
    const [integracaoAsiaUrl, setIntegracaoAsiaUrl] = useState('');
    const [integracaoAsiaAppKey, setIntegracaoAsiaAppKey] = useState('');
    const [integracaoSpotUrl, setIntegracaoSpotUrl] = useState('');
    const [integracaoSpotAccessKey, setIntegracaoSpotAccessKey] = useState('');

    const [confirmDelete, setConfirmDelete] = useState(false);
    

    const storeTenant = () => {
        setLoading(true);

        let form = new FormData;

        let elLogo = document.querySelector('input[name="logo"]');
        let fileLogo = elLogo.files[0];

        if(fileLogo !== undefined){
            form.append('logo_png', fileLogo);
        }

        let elFavicon = document.querySelector('input[name="favicon"]');
        let fileFavicon = elFavicon.files[0];

        if(fileFavicon !== undefined){
            form.append('favicon', fileFavicon);
        }

        form.append('nome', nome);
        form.append('status', status);
        form.append('plano', plano);
        form.append('cor_primaria', corPrimaria);
        form.append('cor_secundaria', corSecundaria);
        form.append('cor_terciaria', corTerciaria);
        form.append('domain', domain);
        form.append('subdomain', subdomain);
        form.append('vercel_project_id', vercelProjectId);
        form.append('integracao_xbz_url', integracaoXbzUrl);
        form.append('integracao_asia_url', integracaoAsiaUrl);
        form.append('integracao_asia_app_key', integracaoAsiaAppKey);
        form.append('integracao_spot_url', integracaoSpotUrl);
        form.append('integracao_spot_access_key', integracaoSpotAccessKey);

        api.post(`/tenant`, form).then(() => {
            toast.success('Cliente cadastrado com sucesso');
            navigate('/tenant/list');
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const deleteTenant = () => {

    }

    const removeLogo = () => {
        setLogoPngUrl(null);
    }

    const removeFavicon = () => {
        setFaviconUrl(null);
    }

    return (
        <>
            <Loading show={loading} />
            <ModalDelete
                show={confirmDelete}
                action={deleteTenant}
                loading={loading}
                close={() => setConfirmDelete(false)}
            />
            <PanelTemplate id="tenant-edit">
                <Box>
                    <div className="row">
                        <div className="col-3">
                            <Input label={`Nome`} value={nome} change={setNome} />
                        </div>
                        <div className="col-3">
                            <InputSelect label={`Plano`} value={plano} options={planosOptions} change={setPlano} />
                        </div>
                        <div className="col-3">
                            <InputSelect label={`Status`} value={status} options={statusOptions} change={setStatus} />
                        </div>
                    </div>

                    <h2 className="section-title">Tema</h2>

                    <div className="row">
                        <div className="col-3">
                            <InputSelect label={`Tema`} value={vercelProjectId} options={projectsOptions} change={setVercelProjectId} />
                        </div>
                        <div className="col-3">
                            <InputColor label={`Cor primária`} value={corPrimaria} change={setCorPrimaria} />
                        </div>
                        <div className="col-3">
                            <InputColor label={`Cor secundária`} value={corSecundaria} change={setCorSecundaria} />
                        </div>
                        <div className="col-3">
                            <InputColor label={`Cor terciária`} value={corTerciaria} change={setCorTerciaria} />
                        </div>
                    </div>

                    

                    <div className="row">
                        
                        <div className="col-6">
                            <h2 className="section-title">Logo</h2>

                            {logoPngUrl &&
                                <div className="tenant-logo">
                                    <img src={`${logoPngUrl}?t=${new Date().getTime()}`} alt={nome} />
                                    <button onClick={removeLogo}>x</button>
                                </div>
                            }
                            <input style={{ marginBottom: 15, display: logoPngUrl ? 'none' : 'block'}} accept="image/png" type="file" name="logo" />
                            {!logoPngUrl &&
                                <p style={{ fontSize: 12}}><b>Formato aceito: PNG. Tamanho ideal: 500px de largura</b></p>
                            }
                        </div>

                        <div className="col-6">
                            <h2 className="section-title">Favicon</h2>

                            {faviconUrl &&
                                <div className="tenant-favicon">
                                    <img src={`${faviconUrl}?t=${new Date().getTime()}`} alt={nome} />
                                    <button onClick={removeFavicon}>x</button>
                                </div>
                            }
                            <input style={{ marginBottom: 15, display: faviconUrl ? 'none' : 'block'}} accept="image/png" type="file" name="favicon" />
                            {!faviconUrl &&
                                <p style={{ fontSize: 12}}><b>Formato aceito: PNG. Tamanho ideal: 32px por 32px</b></p>
                            }
                        </div>
                    </div>

                    <h2 className="section-title">Detalhes dos domínios</h2>

                    <div className="row">
                        <div className="col-4">
                            <Input label={`Domínio do site`} placeholder={`Ex: brindeiro.com.br (sem .www)`} value={domain} change={setDomain} />
                        </div>
                        <div className="col-4">
                            <Input label={`Domínio do site`} value={`www.${domain}`} disabled={true} />
                        </div>
                        <div className="col-4">
                            <Input label={`Subdomínio do sistema`} placeholder={`Ex: sistema.brindeiro.com.br`} value={subdomain} change={setSubdomain} />
                        </div>
                    </div>

                    <h2 className="section-title">Detalhes das integrações</h2>

                    <div className="row">
                        <div className="col-4">
                            <Input label={`XBZ URL`} placeholder={`Ex: https://api.minhaxbz.com.br:5001/api/clientes/GetListaDeProdutos?cnpj=000000000000&token=0A001A25`} value={integracaoXbzUrl} change={setIntegracaoXbzUrl} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <Input label={`ASIA Imports URL`} placeholder={`Ex: https://asia.ajung.site/api/products`} value={integracaoAsiaUrl} change={setIntegracaoAsiaUrl} />
                        </div>
                        <div className="col-4">
                            <Input label={`ASIA Imports App Key`} placeholder={`Ex: W5xvA5og9DbftyswSFI7v`} value={integracaoAsiaAppKey} change={setIntegracaoAsiaAppKey} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <Input label={`SPOT URL`} placeholder={`Ex: http://ws.spotgifts.com.br/api`} value={integracaoSpotUrl} change={setIntegracaoSpotUrl} />
                        </div>
                        <div className="col-4">
                            <Input label={`SPOT Access Key`} placeholder={`Ex: vetasW1CM81ps`} value={integracaoSpotAccessKey} change={setIntegracaoSpotAccessKey} />
                        </div>
                    </div>
                </Box>
                <PageFooter spaceBetween={true}>
                    <Button
                        type={`primary`}
                        size={`small`}
                        svg={`save-white`}
                        text={`Cadastrar cliente`}
                        action={storeTenant}
                        loading={loading}
                    />
                </PageFooter>
            </PanelTemplate>
        </>
    );
}

export default TenantCreate;